import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";
import { paramCase } from "change-case";
import IndustryContainer from "../../containers/IndustryContainer";
import PageBanner from "../../sections/PageBanner";
import PageIntro from "../../sections/PageIntro";
import Box from "../../base/Box";
import HorizontalCardHalfImage from "../../sections/HorizontalCardHalfImage";
import { CommaStringToArray } from "../../helper/helper";
import Section from "../Section";
import AspectRatio from "../AspectRatio";
import Image from "../Image";
import MarkdownToHtml from "../MarkdownToHtml";
import StartRating from "../StarRating";

const IndustryLayout = ({ pageContext }) => {
  const {
    industryData: { id },
  } = pageContext;
  const theme = useContext(ThemeContext);
  return (
    <IndustryContainer>
      {(industryPageData, allProjectsData, clientsData, industriesData) => {
        const {
          heroContent,
          introduction,
          featuredProjects,
          reviews,
          ctaSection,
        } = industriesData.find((i) => paramCase(i.id) === id);

        const pageName = "industry";
        const industrySections = industryPageData
          .map((ele) => (ele.id === pageName ? ele : null))
          .find((el) => el);

        const {
          pageBanner,
          introduction: introSection,
          featuredProjects: featuredSection,
          reviews: reviewsSection,
        } = industrySections;

        const isPageBanner =
          pageBanner && !pageBanner.hideSection && heroContent;
        const isIntroduction =
          introSection && !introSection.hideSection && introduction;

        const featuredProjectsList = CommaStringToArray(featuredProjects);
        const isFeaturedProjects =
          featuredSection &&
          !featuredSection.hideSection &&
          featuredProjectsList &&
          featuredProjectsList.length > 0 &&
          allProjectsData?.length > 0;
        const featuredProjectList =
          isFeaturedProjects &&
          allProjectsData.filter((p) =>
            featuredProjectsList.includes(p.projectId)
          );
        return (
          <>
            {isPageBanner && (
              <PageBanner
                bannerContent={heroContent}
                bgImage={heroContent.image}
                containerSx={{
                  mb: "auto",
                  pb: [5, null, null, null, 6],
                  position: "relative",
                  pt: [5, null, null, null, 6],
                }}
                contentSx={{
                  color: "black",
                  fontSize: 4,
                  h2: {
                    ...theme.variants.markdown.h1,
                    color: "primary",
                  },
                  h3: {
                    color: "grays.1",
                    fontSize: 0,
                  },
                  width: ["100%", null, null, "60%"],
                }}
                sx={{
                  "&:before": {
                    backgroundImage: theme.baseGradients.grays[1],
                    bottom: "0",
                    content: "''",
                    left: "0",
                    opacity: "0.85",
                    position: "absolute",
                    right: "0",
                    top: "0",
                  },
                }}
              />
            )}
            {isIntroduction && (
              <PageIntro
                containerSx={{
                  maxWidth: [
                    null,
                    "maxWidths.sm",
                    "maxWidths.md",
                    "maxWidths.lg",
                  ],
                }}
                contentSx={
                  introduction.images?.length > 0 &&
                  introduction.images.some((i) => i.image)
                    ? {}
                    : { color: "primary", fontSize: 4, textAlign: "center" }
                }
                contentWrapperSx={{
                  width: "auto",
                }}
                data={{
                  ...introduction,
                  textContent: {
                    content: introduction.content,
                    heading: introduction.heading,
                  },
                }}
                page="industries"
              />
            )}
            {isFeaturedProjects &&
              featuredProjectList.map((project, index) => {
                const isEven = (index + 1) % 2 !== 0;
                const isMultipleImages =
                  Array.isArray(project?.projectImage) &&
                  project.projectImage.length > 1;
                return (
                  <HorizontalCardHalfImage
                    key={`${paramCase(project.title)}-${index}`}
                    containerSx={{
                      maxWidth: [
                        null,
                        "maxWidths.sm",
                        "maxWidths.md",
                        "maxWidths.lg",
                        "maxWidths.xl",
                      ],
                      pb: [5, null, null, null, 6],
                      pt: [5, null, null, null, 6],
                    }}
                    contentSx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      mb: [0, null, null, 0, 0],
                      minHeight: [null, null, null, "30rem"],
                      mt: [5, null, null, 0, 0],
                    }}
                    data={{
                      actionButtons: project?.brief?.actionButtons,
                      image: project?.projectImage,
                      textContent: {
                        content: project?.brief?.content,
                        heading: `### Featured Project\n${project?.details?.heading}`,
                      },
                    }}
                    imageSx={{
                      bottom: [null, null, null, 6],
                      img: {
                        boxShadow: isEven
                          ? "10px 10px 30px rgb(0 0 0 / 30%)"
                          : "-10px 10px 30px rgb(0 0 0 / 30%)",
                        [isMultipleImages || isEven ? "ml" : "mr"]:
                          isMultipleImages ? "43.75%" : [null, null, null, 0],
                        mt: isMultipleImages ? "-6.25%" : "",
                        objectFit: "fill",
                        position: [isMultipleImages ? "absolute" : ""],
                        transform: `perspective(5000px) rotateY(-30deg) rotateX(10deg) ${
                          isMultipleImages
                            ? "translate(-50%, 0)"
                            : "translate(-12.5%, 0)"
                        }`,
                        transformOrigin: isEven ? "100% center" : "0 center",
                      },
                      "img:nth-child(2)": {
                        ml: "56.25%",
                        mt: "6.25%",
                        zIndex: "-1",
                      },
                      minHeight: "32rem",
                      textAlign: "center",
                      top: [null, null, null, 6],
                      width: ["100%", null, null, "45%"],
                    }}
                    isEven={isEven}
                    page="projects"
                    sectionSx={{
                      bg: "primary",
                    }}
                    textContentSx={{
                      color: "white",
                      h2: {
                        color: "white",
                      },
                      h3: {
                        color: "white",
                        fontSize: 0,
                      },
                    }}
                  />
                );
              })}
            {reviews?.length > 0 && !reviewsSection.hideSection && (
              <Section
                containerSx={{
                  maxWidth: [null, "maxWidths.sm", null, "maxWidths.lg"],
                  my: -4,
                }}
                sx={{ bg: "white" }}
              >
                {reviews.map((review, index) => {
                  const { image, ratings, content, heading } = review;
                  const isEven = (index + 1) % 2 !== 0;
                  return (
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: [
                          "column",
                          null,
                          null,
                          isEven ? "row-reverse" : "row",
                        ],
                        py: 4,
                      }}
                    >
                      <AspectRatio
                        sx={{
                          img: { height: "100%" },
                          width: ["100%", null, null, "45%"],
                        }}
                      >
                        <Image
                          img={image}
                          sx={{
                            height: "auto",
                            objectFit: "cover",
                            width: "100%",
                          }}
                        />
                      </AspectRatio>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                          width: ["100%", null, null, "55%"],
                          [isEven ? "pr" : "pl"]: [null, null, 4, 6],
                        }}
                      >
                        <Image
                          svgIcon="quote-square"
                          sx={{
                            color: "primaryDark",
                            my: [3, null, null, 4],
                            width: "4rem",
                          }}
                        />
                        <MarkdownToHtml
                          heading={heading}
                          source={content}
                          sx={{
                            h2: {
                              color: "text.heading",
                              fontWeight: "medium",
                            },
                          }}
                        />
                        <StartRating initialRating={ratings} readonly />
                      </Box>
                    </Box>
                  );
                })}
              </Section>
            )}
            {ctaSection && !ctaSection.hideSection && (
              <PageIntro
                contentSx={{
                  h2: {
                    color: "primary",
                    fontSize: 5,
                  },
                }}
                contentWrapperSx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  pr: 0,
                  textAlign: "center",
                  width: "100%",
                }}
                data={ctaSection}
                page="industry"
                sectionSx={{
                  bg: "grays.0",
                }}
              />
            )}
          </>
        );
      }}
    </IndustryContainer>
  );
};

IndustryLayout.propTypes = {
  pageContext: PropTypes.shape({
    industryData: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default IndustryLayout;
